import React, { useState } from 'react';
import VBox from '@shared/ui/layouts/Vbox';
import SimpleBtn from '@shared/ui/buttons/SimpleBtn';
import handleAsyncNotification from "@shared/helpers/handleAsyncNotification";
import Select from "@shared/ui/Select";
import makeRequest from "@shared/api/makeRequest";
import GlassCard from "@shared/ui/GlassCard";
import Vbox from "@shared/ui/layouts/Vbox";
import Text from "@shared/ui/Text";
import ElementCard from "@shared/ui/ElementCard";
import { LinkOutlined } from '@ant-design/icons';
import ClipboardCopy from '@shared/ui/ClipboardCopy';
import SimpleInput from "@shared/ui/Simpleinput";
import { Controller, useForm } from 'react-hook-form';
import {TwoColumns} from "@shared/ui/containers/ListContainers";


const mailInput = {
    pattern: {
        value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/, // Регулярное выражение для проверки формата email
        message: "Введите корректный адрес электронной почты"
    },
    label: "Почта", 
    value: "email", 
    required: true, 
}

const chatUrlInput = {
    pattern: {
        value: /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/,
        message: "Введите корректный URL"
    },
    label: "Чат урл", 
    value: "chat_url"
}

const usernameInput = {
    pattern: {
        value: /^[a-zA-Z0-9]+$/, // Только латинские символы и цифры
        message: "Разрешены только латинские буквы и цифры"
    },
    label: "Имя пользователя",
    value: "username",
    required: true
}

const tgInput = {
    pattern: {
        value: /^[a-zA-Z0-9]+$/, // Только латинские символы и цифры
        message: "Разрешены только латинские буквы и цифры"
    },
    label: "Имя пользователя ТГ",
    value: "telegram_name",
    required: true
}

const inputs = {
    trader: [
        { label: "Мин. страховой депозит", value: "deposit", number: true, required: true },
        chatUrlInput,
        tgInput,
        usernameInput,
        mailInput
    ],
    merchant: [
        chatUrlInput,
        tgInput,
        usernameInput,
        mailInput
    ],
    agent: [
        chatUrlInput,
        tgInput,
        usernameInput,
        mailInput
    ],
    support: [
        mailInput,
        tgInput,
        usernameInput,
    ],
    cascade: [
        chatUrlInput,
        tgInput,
        usernameInput,
        mailInput
    ],
    analyst: [
        mailInput,
        usernameInput,
        tgInput
    ]
}


const InvitePeople = ({roles}) => {
    const [url, setUrl] = useState();
    const { handleSubmit, control, reset, watch, formState: { errors } } = useForm();
    const role = watch('role') || roles[0].value;
    
    return <GlassCard>
        <Text weight="bold">Создание ссылки-приглашения</Text>
        <ElementCard>
            <VBox gap='20px'>
                <form onSubmit={handleSubmit(createUrl)}>
                    <Vbox gap="10px">
                        <TwoColumns>
                            <Controller
                                name="role"
                                defaultValue={roles[0].value}
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        label="Роль пользователя"
                                        options={roles}
                                        error={errors.role?.message}
                                    />
                                )}
                            />
                            {inputs[role]?.map(({value, label, number, pattern, required}) => <Controller
                                name={value}
                                control={control}
                                key={value}
                                rules={{
                                    required: required ? "Поле обязательно для заполнения" : undefined,
                                    pattern
                                }}
                                render={({ field }) => (
                                    <SimpleInput
                                        {...field}
                                        label={label}
                                        number={number}
                                        error={errors[value]?.message}
                                    />
                                )}
                            />)}
                        </TwoColumns>
                        <Vbox gap="10px">
                            {url && <ClipboardCopy value={url} />}
                            <SimpleBtn icon={<LinkOutlined />} main type="submit">Создать ссылку</SimpleBtn>
                            {url && <SimpleBtn onClick={clearForm}>Очистить</SimpleBtn>}
                        </Vbox>
                    </Vbox>
                    
                </form>
            </VBox>
        </ElementCard>
    </GlassCard>

    async function createUrl({role, username, telegram_name, email, deposit, chat_url}) {
        await handleAsyncNotification(async () => {
            const { url } = await makeRequest('/api/v1/core/account/invite', { body: {
                role,
                telegram_name,
                email,
                deposit,
                chat_url,
                username
            }});

            setUrl(url)
        }, {
            successText: "Ссылка создана",
            errorText: "Ошибка создания ссылки",
            loadingText: "Создание ссылки"
        });
    }

    function clearForm() {
        reset();
        setUrl("");
    }
}
export default InvitePeople;